<template>
  <div id="request">
    <div class="lists-header">
      <v-toolbar flat class="flex-column">
        <v-text-field :value="filter.search" @change="value => filter.search = value" placeholder="例：商品ページ修正" filled rounded prepend-inner-icon="mdi-magnify" hide-details dense clearable></v-text-field>

        <v-menu :close-on-content-click="false" :max-width="320" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon size="20">mdi-filter-menu</v-icon>
            </v-btn>
          </template>

          <v-card class="filter-menu">
            <v-divider />
            <v-card-text>
              <v-row dense>
                <!-- クライアント -->
                <v-col cols="12">
                  <v-autocomplete
                      v-model="filter.end_user"
                      :items="filterUserLists.endUsers"
                      item-text="end_company_name"
                      cache-items
                      placeholder="お客様を選択"
                      hide-no-data
                      hide-details
                      label="クライアント"
                      return-object
                  />
                </v-col>
                <!-- サポート担当者 -->
                <v-col cols="12">
                  <v-select v-model="filter.admin_user" :items="filterUserLists.adminUsers" item-text="name" item-value="id" label="サポート担当者" return-object hide-details></v-select>
                </v-col>
                <!-- タスク所有者 -->
                <v-col cols="12">
                  <v-select v-model="filter.assigned_login" :items="filterUserLists.adminUsers" item-text="name" item-value="id" label="タスク所有者" return-object hide-details></v-select>
                </v-col>
                <!-- 登録日開始 -->
                <v-col cols="12" sm="6">
                  <v-menu v-model="isFilterSelecting.regist_date_from" :close-on-content-click="false" offset-y left min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="filter.regist_date_from" label="登録日" readonly v-bind="attrs" v-on="on" suffix="〜"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.regist_date_from" @input="isFilterSelecting.regist_date_from = false" no-title first-day-of-week="1" :day-format="date => new Date(date).getDate()"></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- 登録日終了 -->
                <v-col cols="12" sm="6">
                  <v-menu v-model="isFilterSelecting.regist_date_to" :close-on-content-click="false" offset-y left min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="filter.regist_date_to" readonly v-bind="attrs" v-on="on" suffix=""></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.regist_date_to" @input="isFilterSelecting.regist_date_to = false" no-title first-day-of-week="1" :day-format="date => new Date(date).getDate()"></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- 公開希望日開始 -->
                <v-col cols="12" sm="6">
                  <v-menu v-model="isFilterSelecting.release_date_from" :close-on-content-click="false" offset-y left min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="filter.release_date_from" readonly label="公開希望日" v-bind="attrs" v-on="on" suffix="〜"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.release_date_from" @input="isFilterSelecting.release_date_from = false" no-title first-day-of-week="1" :day-format="date => new Date(date).getDate()"></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- 公開希望日終了 -->
                <v-col cols="12" sm="6">
                  <v-menu v-model="isFilterSelecting.release_date_to" :close-on-content-click="false" offset-y left min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="filter.release_date_to" readonly v-bind="attrs" v-on="on" suffix=""></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.release_date_to" @input="isFilterSelecting.release_date_to = false" no-title first-day-of-week="1" :day-format="date => new Date(date).getDate()"></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- 計上日開始 -->
                <v-col cols="12" sm="6">
                  <v-menu v-model="isFilterSelecting.record_date_from" :close-on-content-click="false" offset-y left min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="filter.record_date_from" label="計上日" readonly v-bind="attrs" v-on="on" suffix="〜"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.record_date_from" @input="isFilterSelecting.record_date_from = false" no-title first-day-of-week="1" :day-format="date => new Date(date).getDate()"></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- 計上日終了 -->
                <v-col cols="12" sm="6">
                  <v-menu v-model="isFilterSelecting.record_date_to" :close-on-content-click="false" offset-y left min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="filter.record_date_to" label="" readonly v-bind="attrs" v-on="on" suffix=""></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.record_date_to" @input="isFilterSelecting.record_date_to = false" no-title first-day-of-week="1" :day-format="date => new Date(date).getDate()"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-spacer />
        <vue-json-to-csv :json-data="exportOptions.data" :labels="exportOptions.labels" :csv-title="exportOptions.filename" @success="val => downloadCSVSuccess(val)">
          <v-btn elevation="0" @click="downloadCSV" :disabled="requests.length == 0" text><v-icon left>mdi-download-circle-outline</v-icon><span class="hidden-sm-and-down">CSVダウンロード</span></v-btn>
        </vue-json-to-csv>

        <template slot="extension">
          <v-chip v-if="filter.end_user.id" close @click:close="filter.end_user = ''" outlined>クライアント {{filter.end_user.end_company_name}}</v-chip>
          <v-chip v-if="filter.admin_user" close @click:close="filter.admin_user = ''" outlined>担当者 {{filter.admin_user.name}}</v-chip>
          <v-chip v-if="filter.assigned_login" close @click:close="filter.assigned_login = ''" outlined>タスク所有者 {{filter.assigned_login.name}}</v-chip>
          <v-chip v-if="filter.regist_date_from || filter.regist_date_to" close @click:close="filter.regist_date_from = filter.regist_date_to = ''" outlined>登録日 {{filter.regist_date_from}}〜{{filter.regist_date_to}}</v-chip>
          <v-chip v-if="filter.release_date_from || filter.release_date_to" close @click:close="filter.release_date_from = filter.release_date_to = ''" outlined>公開希望日 {{filter.release_date_from}}〜{{filter.release_date_to}}</v-chip>
          <v-chip v-if="filter.record_date_from || filter.record_date_to" close @click:close="filter.record_date_from = filter.record_date_to = ''" outlined>計上日 {{filter.record_date_from}}〜{{filter.record_date_to}}</v-chip>
        </template>
      </v-toolbar>

    </div>

    <v-container class="max-w-none">
        <v-card>
          <!-- <v-card-text> -->
          <v-data-table class="clickable" :headers="headers" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, 250, 500, 1000], showFirstLastPage: true }" :items="requests" :options.sync="options" :server-items-length="totalPassengers" :loading="loading" @click:row="changeView" >
            <template v-slot:[`item.end_user_id`]="{ item }">
              <template v-if="item.end_user">{{ item.end_user.end_company_name }}</template>
            </template>
            <template v-slot:[`item.assigned_login_id`]="{ item }">
              <template v-if="item.assigned_login">
                <div style="white-space: nowrap;">
                  <LoginAvatar :user="item.assigned_login" :size="32" :avatarClass="['d-inline-block','mr-1']" />
                  {{ item.assigned_login.name }}
                </div>
              </template>
            </template>
            <template v-slot:[`item.current_status_id`]="{ item }">
              <div v-if="item.current_status" class="caption">{{ item.current_status.status }}</div>
              <v-progress-linear rounded v-if="item.current_status_id === 8" color="warning" :value="0"></v-progress-linear>
              <v-progress-linear rounded v-else :value="getStepPercentage(item.current_status_id)" color="primary"></v-progress-linear>
            </template>
            <template v-slot:[`item.id`]="{ item }">
              <v-chip small v-if="item.current_status_id === 8" color="grey lighten-4">#{{ item.id }}</v-chip>
              <v-chip small v-else color="primary">#{{ item.id }}</v-chip>
              <!-- <v-chip small color="primary">#{{ item.id }}</v-chip> -->
            </template>
            <template v-slot:[`item.subject`]="{ item }">{{ item.subject }}</template>
            <template v-slot:[`item.point_total`]="{ item }">
              {{ item.point_total }}<span class="unit" v-show="item.point_total != null">Pt</span>
            </template>
            <template v-slot:[`item.release_date`]="{ item }">
              {{ item.release_date | moment("YYYY/MM/DD(ddd)") }} {{ item.release_time }}
            </template>
            <template v-slot:[`item.comments_number`]="{ item }">
              <template v-if="item.comments_number">
                <v-tooltip top content-class="pl-0 pa-1" :disabled="!item.request_summary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge color="grey" overlap :content="item.comments_number" class="scale-90">
                      <v-icon color="grey" v-bind="attrs" v-on="on">{{ item.request_summary ? 'mdi-comment-text-outline' : 'mdi-comment-outline' }}</v-icon>
                    </v-badge>
                  </template>
                  <span class="d-block" v-html="item.request_summary"></span>
                </v-tooltip>
              </template>
            </template>
          </v-data-table>
          <!-- </v-card-text> -->
        </v-card>
    </v-container>
  </div>
</template>

<script>
import LoginAvatar from '@/components/LoginAvatar.vue'
import axios from '@/plugins/axios'
import VueJsonToCsv from 'vue-json-to-csv'

export default {
  name: 'request',
  components: {
    LoginAvatar,
    VueJsonToCsv
  },
  data: function () {
    return {
      key: {
        altKeyOn: false,
        shiftKeyOn: false,
        ctrlKeyOn: false,
        metaKeyOn: false
      },
      loading: true,
      totalPassengers: 0,
      options: JSON.parse(sessionStorage.getItem('requests.options')) || {
        itemsPerPage: 25,
        sortDesc: [
          true
        ]
      },
      headers: [
        {
          text: 'ステータス',
          width: 116,
          value: 'current_status_id',
          align: 'center'
        },
        {
          text: '依頼番号',
          value: 'id',
          width: 104,
          cellClass: 'cell_id'
        },
        {
          text: '件名',
          value: 'subject',
          cellClass: 'cell_title'
        },
        {
          text: 'コメント',
          value: 'comments_number',
          align: 'center',
          width: 104
        },
        {
          text: 'タスク所有者',
          value: 'assigned_login_id',
          width: 150
        },
        {
          text: 'Pt',
          value: 'point_total',
          align: 'end',
          width: 80
        },
        {
          text: '公開日時',
          value: 'release_date',
          align: 'center',
          width: 128
        },
        {
          text: '依頼元',
          value: 'end_user_id',
          width: 192
        }
      ],
      requests: [],
      filter: JSON.parse(sessionStorage.getItem('requests.filter')) || {
        search: '',
        end_user: { id: this.$route.query.end_user_id, end_company_name: this.$route.query.end_company_name },
        admin_user: '',
        assigned_login: '',
        regist_date_from: '',
        regist_date_to: '',
        release_date_from: this.$route.query.release_date_from,
        release_date_to: this.$route.query.release_date_to,
        record_date_from: '',
        record_date_to: ''
      },
      isFilterSelecting: {
        regist_date_from: false,
        regist_date_to: false,
        release_date_from: false,
        release_date_to: false,
        record_date_from: false,
        record_date_to: false
      },
      filterUserLists: {
        endUsers: [],
        adminUsers: []
      },
      exportOptions: {
        data: [],
        labels: {
          id: { title: '依頼番号' },
          end_company_name: { title: 'クライアント名' },
          create_at: { title: '依頼日' },
          point_allocate_date: { title: '計上日' },
          release_date: { title: '公開希望日' },
          release_time: { title: '公開希望時間' },
          submit_login_name: { title: '依頼者名' },
          submit_login_phone: { title: '電話番号' },
          submit_login_email: { title: 'メールアドレス' },
          subject: { title: '依頼タイトル' },
          request_content: { title: '依頼内容' },
          current_status: { title: '現在ステータス' },
          total_worked: { title: '作業時間合計（分）' },
          point_total: { title: '使用ポイント' },
          point_rate: { title: 'ポイント倍率' }
        },
        filename: ''
      }
    }
  },
  watch: {
    $route: {
      handler () {
        this.options.page = 1
        this.readDataFromAPI()
      }
    },
    filter: {
      handler () {
        this.options.page = 1
        this.readDataFromAPI()
      },
      deep: true
    },
    options: {
      handler () {
        this.readDataFromAPI()
      },
      deep: true
    }
  },
  methods: {
    changeView: function (value) {
      const routeOption = { name: 'admin:request_show', params: { id: value.id } }
      if (this.key.altKeyOn === true || this.key.shiftKeyOn === true || this.key.ctrlKeyOn === true || this.key.metaKeyOn === true) {
        window.open(this.$router.resolve(routeOption).href)
      } else {
        this.$router.push(routeOption)
      }
    },
    readDataFromAPI: function () {
      this.loading = true
      const queryParams = {
        sort: this.options.sortBy.length === 0 ? 'id' : this.options.sortBy[0],
        direction: this.options.sortDesc.length === 0 ? 'desc' : (this.options.sortDesc[0] ? 'desc' : 'asc'),
        per_page: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 0,
        page: this.options.page
      }
      if (this.$route.params.fillter) {
        queryParams.schedule = this.$route.params.fillter
      }
      if (this.filter.search) {
        queryParams.query = this.filter.search
      }
      // Filter menu options ↓
      if (this.filter.end_user.id) {
        queryParams.client = this.filter.end_user.id
      }
      if (this.filter.admin_user) {
        queryParams.admin = this.filter.admin_user.id
      }
      if (this.filter.assigned_login) {
        queryParams.assigned_login_id = this.filter.assigned_login.id
      }
      if (this.filter.regist_date_from) {
        queryParams.regF = this.filter.regist_date_from
      }
      if (this.filter.release_date_from) {
        queryParams.relF = this.filter.release_date_from
      }
      if (this.filter.record_date_from) {
        queryParams.recF = this.filter.record_date_from
      }
      if (this.filter.regist_date_to) {
        queryParams.regT = this.filter.regist_date_to
      }
      if (this.filter.release_date_to) {
        queryParams.relT = this.filter.release_date_to
      }
      if (this.filter.record_date_to) {
        queryParams.recT = this.filter.record_date_to
      }
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request`, {
        params: queryParams
      })
        .then(res => {
          this.requests = res.data.data
          this.totalPassengers = res.data.total
          // 検索条件をセッションに保存
          sessionStorage.setItem('requests.filter', JSON.stringify(this.filter))
          sessionStorage.setItem('requests.options', JSON.stringify(this.options))
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    downloadCSV: function () {
      this.requests.forEach((request) => {
        this.exportOptions.data.push({
          id: request.id,
          end_company_name: request.end_user.id ? request.end_user.end_company_name.split('"').join('""') : '',
          create_at: this.$moment(request.create_at).isValid() ? this.$moment(request.create_at).format('YYYY-MM-DD HH:mm:ss') : '',
          point_allocate_date: this.$moment(request.point_allocate_date).isValid() ? this.$moment(request.point_allocate_date).format('YYYY-MM-DD') : '',
          release_date: request.release_date,
          release_time: request.release_time,
          submit_login_name: request.submit_login?.name ? request.submit_login?.name.split('"').join('""') : '',
          submit_login_phone: request.submit_login?.phone ? request.submit_login?.phone.split('"').join('""') : '',
          submit_login_email: request.submit_login?.email ? request.submit_login?.email.split('"').join('""') : '',
          subject: request.subject ? request.subject.split('"').join('""') : '',
          request_content: request.request_content ? request.request_content.split('"').join('""') : '',
          current_status: request.current_status?.status,
          total_worked: request.total_worked,
          point_total: request.point_total,
          point_rate: request.point_rate
        })
      })
      this.exportOptions.filename = 'requests_' + this.$moment().format('YYYYMMDDHHmmss')
    },
    downloadCSVSuccess () {
      this.exportOptions.data.splice(0)
    },
    getStepPercentage: function (step) {
      if (step === 8) return 0
      var allStatus = this.$store.getters.getAllStatus
      return Math.ceil(step / (allStatus.length - 1) * 100)
    }
  },
  beforeCreate: function () {
    // 新しいクエリパラメータがある場合、セッションに保存している検索条件をクリア
    if (Object.keys(this.$route.query).length > 0) {
      const filter = JSON.parse(sessionStorage.getItem('requests.filter'))
      let clear = false
      if (filter) {
        if (filter.end_user.id !== this.$route.query.end_user_id) clear = true
        if (filter.release_date_from !== this.$route.query.release_date_from) clear = true
        if (filter.release_date_to !== this.$route.query.release_date_to) clear = true
      }
      if (clear) {
        sessionStorage.removeItem('requests.filter')
        sessionStorage.removeItem('requests.options')
      }
    }
  },
  created: function () {
    const self = this

    // フィルターオプション取得
    const queryParams = {
      per_page: 1000
    }
    axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user`, {
      params: queryParams
    })
      .then(res => {
        this.filterUserLists.endUsers = res.data.data
      })
      .catch(err => {
        console.log(err)
      })
    axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/login`, {
      params: queryParams
    })
      .then(res => {
        this.filterUserLists.adminUsers = res.data.data
      })
      .catch(err => {
        console.log(err)
      })

    // キーイベント監視
    self.keyDownHandler = function (event) {
      self.key.altKeyOn = event.altKey
      self.key.shiftKeyOn = event.shiftKey
      self.key.ctrlKeyOn = event.ctrlKey
      self.key.metaKeyOn = event.metaKey
    }
    self.keyUpHandler = function (event) {
      self.key.altKeyOn = event.altKey
      self.key.shiftKeyOn = event.shiftKey
      self.key.ctrlKeyOn = event.ctrlKey
      self.key.metaKeyOn = event.metaKeyOn
    }
    window.addEventListener('keydown', self.keyDownHandler)
    window.addEventListener('keyup', self.keyUpHandler)
  },
  beforeDestroy: function () {
    window.removeEventListener('keydown', this.keyDownHandler)
    window.removeEventListener('keyup', this.keyUpHandler)
  }
}
</script>
